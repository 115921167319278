<template>
  <div v-if="isshowgrid">
    <va-card title="Domain List">
      <template slot="actions">
        <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
      </template>
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)">
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
          <va-button flat small color="red" icon="fa fa-remove" @click="deleteConfig(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
    <va-modal
      v-model="showRemoveModal"
      title="Remove Org"
      size='small'
      :message="message"
      okText="Confirm"
      cancelText="Cancel"
      @ok="deleteConfiguration(removed_row_content)"
      @cancel="cancel()">
    </va-modal>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card v-if="isCreate" title="CREATE DOMAIN CONFIGURATION">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <va-input
              v-model="form_data.upstream"
              label="Upstream *"
              placeholder="Enter Upstream"
              :error="!!error_msg.upstreamErrors.length"
              :error-messages="error_msg.upstreamErrors"
            />
            <va-input
              v-model="form_data.upstream_port"
              type="number"
              maxlength=5
              label="Upstream Port *"
              placeholder="Enter Upstream Port (Max 65535)"
              :error="!!error_msg.portErrors.length"
              :error-messages="error_msg.portErrors"
            />
            <va-input
              v-model="form_data.failover_upstream"
              label="Failover Upstream *"
              placeholder="Enter Failover Upstream"
              :error="!!error_msg.failoverupstreamErrors.length"
              :error-messages="error_msg.failoverupstreamErrors"
            />
            <va-input
              v-model="form_data.failover_upstream_port"
              type="number"
              maxlength=5
              label="Failover Upstream Port *"
              placeholder="Enter Failover Upstream Port (Max 65535)"
              :error="!!error_msg.failoverportErrors.length"
              :error-messages="error_msg.failoverportErrors"
            />
            <va-input
              v-model="form_data.ip_address"
              type="text"
              label="Access IP *"
              pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
              placeholder="Enter IP (eg:1.3.2.1)"
              :error="!!error_msg.unicastIPErrors.length"
              :error-messages="error_msg.unicastIPErrors"
            />
            <va-input
              v-model="form_data.reference_name"
              type="text"
              label="Domain Reference Name *"
              placeholder="Enter Domain Reference Name"
              pattern="^[A-Za-z0-9-()]+(?: +[A-Za-z0-9-()]+)*$"
              :error="!!error_msg.ReferenceErrors.length"
              :error-messages="error_msg.ReferenceErrors"
            />
            <va-select
              label="Play Context *"
              v-model="form_data.context"
              textBy="id"
              multiple
              :options="play_array"
              :error="!!error_msg.PlayErrors.length"
              :error-messages="error_msg.PlayErrors"
            />
            <div class="radio_fieldset">
              <fieldset class="radio_options">
                <va-radio-button
                  option="Public"
                  v-model="form_data.config_type"
                  :color="error_msg.option && 'danger'" />
                <va-radio-button
                  option="Private"
                  v-model="form_data.config_type"
                  :color="error_msg.option && 'danger'" />
              </fieldset>
            </div>
            <div v-if="form_data.config_type == 'Public'">
              <va-input
                label="Access Domain"
                type="text"
                v-model="form_data.domain_name"
                placeholder="Enter the Domain name"
                :error="!!error_msg.domain_name.length"
                :error-messages="error_msg.domain_name" />
            </div>
            <div v-if="form_data.config_type == 'Private'">
              <va-checkbox
                label="SSL Required"
                v-model="form_data.is_ssl"
              />
            </div>
            <va-checkbox
              label="Upstream SSL Required"
              v-model="form_data.is_upstream_ssl"
            />
            <div class="d-flex justify--center mt-3">
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createConfig()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateConfig()">Update</va-button>
              <va-button type="submit" class="my-0" @click.prevent="list()">Cancel</va-button>
            </div>
          </form>
        </div>
      </va-card>
      <va-card v-if="isUpdate" title="Update Configuration">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <va-input
              v-model="form_data.upstream"
              label="Upstream *"
              placeholder="Enter Upstream"
              :error="!!error_msg.upstreamErrors.length"
              :error-messages="error_msg.upstreamErrors"
            />
            <va-input
              v-model="form_data.upstream_port"
              type="number"
              maxlength=5
              label="Upstream Port *"
              placeholder="Enter Upstream Port (Max 65535)"
              :error="!!error_msg.portErrors.length"
              :error-messages="error_msg.portErrors"
            />
            <va-input
              v-model="form_data.failover_upstream"
              label="Failover Upstream *"
              placeholder="Enter Failover Upstream"
              :error="!!error_msg.failoverupstreamErrors.length"
              :error-messages="error_msg.failoverupstreamErrors"
            />
            <va-input
              v-model="form_data.failover_upstream_port"
              type="number"
              maxlength=5
              label="Failover Upstream Port *"
              placeholder="Enter Failover Upstream Port (Max 65535)"
              :error="!!error_msg.failoverportErrors.length"
              :error-messages="error_msg.failoverportErrors"
            />
            <va-input
              v-model="form_data.ip_address"
              type="text"
              label="IP *"
              pattern="^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
              placeholder="Enter IP (eg:1.3.2.1)"
              :error="!!error_msg.unicastIPErrors.length"
              :error-messages="error_msg.unicastIPErrors"
            />
            <va-input
              v-model="form_data.reference_name"
              type="text"
              label="Domain Reference Name *"
              placeholder="Enter Domain Reference Name"
              pattern="^[A-Za-z0-9-()]+(?: +[A-Za-z0-9-()]+)*$"
              :error="!!error_msg.ReferenceErrors.length"
              :error-messages="error_msg.ReferenceErrors"
            />
            <va-select
              label="Play Context *"
              v-model="form_data.context"
              textBy="id"
              multiple
              :options="play_array"
              :error="!!error_msg.PlayErrors.length"
              :error-messages="error_msg.PlayErrors"
            />
            <div class="radio_fieldset">
              <fieldset class="radio_options">
                <va-radio-button
                  option="Public"
                  v-model="form_data.config_type"
                  :error="!!error_msg.radioErrors.length"
                  :error-messages="error_msg.radioErrors"
                  :color="error_msg.option && 'danger'" />
                <va-radio-button
                  option="Private"
                  v-model="form_data.config_type"
                  :error="!!error_msg.radioErrors.length"
                  :error-messages="error_msg.radioErrors"
                  :color="error_msg.option && 'danger'" />
              </fieldset>
            </div>
            <div v-if="form_data.config_type == 'Public'">
              <va-input
                label="Domain"
                type="text"
                v-model="form_data.domain_name"
                placeholder="Enter the Domain name"
                :error="!!error_msg.domain_name.length"
                :error-messages="error_msg.domain_name" />
            </div>
            <div v-if="form_data.config_type == 'Private'">
              <va-checkbox
                label="SSL Required"
                v-model="form_data.is_ssl"
              />
            </div>
            <va-checkbox
              label="Upstream SSL Required"
              v-model="form_data.is_upstream_ssl"
            />
            <div class="d-flex justify--center mt-3">
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createConfig()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateConfig()">Update</va-button>
              <va-button type="submit" class="my-0" @click.prevent="list()">Cancel</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
Vue.use(vueResource)

export default {
  name: 'config',
  beforeCreate () {
    this.$http.get(config.menu.host + 'config').then(response => {
      this.conf_data = (response.body.length > 0) ? response.body : []
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllConfigs()
    })
  },
  data () {
    return {
      term: null,
      conf_data: [],
      message: '',
      context_object: [],
      play_array: [],
      form_data: {
        domain_name: '',
        reference_name: '',
        upstream: '',
        upstream_port: '',
        failover_upstream: '',
        failover_upstream_port: '',
        config_type: '',
        ip_address: '',
        is_ssl: true,
        is_upstream_ssl: true,
        context: [],
        context_template: [],
      },
      error_msg: {
        option: true,
        upstreamErrors: [],
        failoverupstreamErrors: [],
        failoverportErrors: [],
        radioErrors: [],
        portErrors: [],
        domain_name: [],
        unicastIPErrors: [],
        ReferenceErrors: [],
        PlayErrors: [],
      },
      perPage: '6',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: true,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      isCreate: false,
      isUpdate: false,
      showRemoveModal: false,
    }
  },
  computed: {
    formReady () {
      return !this.error_msg.upstreamErrors.length && !this.error_msg.radioErrors.length && !this.error_msg.unicastIPErrors.length && !this.error_msg.PlayErrors.length && !this.error_msg.failoverportErrors.length && !this.error_msg.failoverupstreamErrors.length && !this.error_msg.portErrors.length && !this.error_msg.ReferenceErrors.length
    },
    fields () {
      return [{
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'reference_name',
        title: 'Domain Reference Name',
      },
      {
        name: 'upstream',
        title: 'Upstream',
      },
      {
        name: 'upstream_port',
        title: 'Upstream Port',
      },
      {
        name: 'config_type',
        title: 'Configuration Type',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      var gridData = search(this.term, this.conf_data)
      if (gridData.length > 0) {
        gridData.map(function (item) {})
      }
      return gridData
    },
  },
  methods: {
    getAllConfigs () {
      this.$http.get(config.menu.host + 'config').then(response => {
        this.conf_data = (response.body.length > 0) ? response.body : []
        this.isshowgrid = true
      })
    },
    getAllContext () {
      this.$http.get(config.menu.skie_url + 'direct_play/context').then(response => {
        this.context_object = (response.body.length > 0) ? response.body : []
        var play_array = []
        if (response.body.length > 0) {
          response.body.map(function (item) {
            var id = item.id
            var context = item.context
            var payload = {
              id: context,
            }
            play_array.push(payload)
          })
        } else {
          play_array = []
        }
        this.play_array = play_array
      })
    },
    templateData () {
      var ref = this
      var context = this.form_data.context[0].id
      var template_array = []
      // this.form_data.context.map(function (item){
      this.context_object.map(function (data) {
	          if (data.context == context) {
          template_array.push(data.play_template)
	          }
        // if(data.context == item.id){
        // }
        // })
      })
      return template_array
    },
    createConfig () {
      this.reset()
      this.error_msg.upstreamErrors = this.form_data.upstream ? [] : ['Upstream is required']

      if (!this.form_data.upstream_port || this.form_data.upstream_port > 65535) {
        this.error_msg.portErrors.push('Invalid Port')
      }

      this.error_msg.failoverupstreamErrors = this.form_data.failover_upstream ? [] : ['Failover Upstream is required']

      if (!this.form_data.failover_upstream_port || this.form_data.failover_upstream_port > 65535) {
        this.error_msg.failoverportErrors.push('Invalid Port')
      }

      this.error_msg.PlayErrors = this.form_data.context ? [] : ['Context is required']
      this.error_msg.unicastIPErrors = this.form_data.ip_address ? [] : ['IP  is required']
      this.error_msg.radioErrors = this.form_data.config_type ? [] : ['Config type is required']
      this.error_msg.ReferenceErrors = this.form_data.reference_name ? [] : ['Domain Reference Name is required']

      if (!this.formReady) {
        return
      }

      this.form_data.context_object = this.templateData()
      this.form_data.context = JSON.stringify(this.form_data.context)
      this.$http.post(config.menu.host + 'config', this.form_data).then(responseData => {
        if (responseData && responseData.body) {
          Vue.notify({
            text: responseData.body,
            type: 'success',
          })
        }
        this.list()
      }, errResponse => {
        if (errResponse && errResponse.body) {
          Vue.notify({
            text: errResponse.body,
            type: 'error',
          })
        }
      })
    },
    updateConfig () {
      this.reset()
      this.error_msg.upstreamErrors = this.form_data.upstream ? [] : ['Upstream is required']

      if (!this.form_data.upstream_port || this.form_data.upstream_port > 65535) {
        this.error_msg.portErrors.push('Invalid Port')
      }

      this.error_msg.failoverupstreamErrors = this.form_data.failover_upstream ? [] : ['Failover Upstream is required']

      if (!this.form_data.failover_upstream_port || this.form_data.failover_upstream_port > 65535) {
        this.error_msg.failoverportErrors.push('Invalid Port')
      }

      this.error_msg.PlayErrors = this.form_data.context ? [] : ['Context is required']
      this.error_msg.unicastIPErrors = this.form_data.ip_address ? [] : ['IP  is required']
      this.error_msg.radioErrors = this.form_data.config_type ? [] : ['Config type is required']
      this.error_msg.ReferenceErrors = this.form_data.reference_name ? [] : ['Domain Reference Name is required']

      if (!this.formReady) {
        return
      }

      this.form_data.context_object = this.templateData()
      this.form_data.context = JSON.stringify(this.form_data.context)
      this.$http.put(config.menu.host + 'config/' + this.form_data.config_id, this.form_data).then(responseData => {
        if (responseData && responseData.body) {
          Vue.notify({
            text: responseData.body,
            type: 'success',
          })
        }
        this.list()
      }, errResponse => {
        if (errResponse && errResponse.body) {
          Vue.notify({
            text: errResponse.body,
            type: 'error',
          })
        }
      })
    },
    deleteConfig (rowData) {
      this.message = 'Are you sure to delete ' + rowData.reference_name + ' Config ?'
      this.showRemoveModal = true
      this.removed_row_content = rowData
    },
    deleteConfiguration (rowData) {
      clearInterval(this.interval)
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.delete(config.menu.host + 'config/' + rowData.config_id).then(response => {
        loader.hide()
        if (response && response.body) {
          Vue.notify({
            text: response.body,
            type: 'success',
          })
        }
        this.list()
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({
            text: error.body,
            type: 'error',
          })
        }
      })
    },
    edit (row) {
      this.reset()
      this.getAllContext()
      var play_json = []
      play_json = JSON.parse(row.context)
      this.form_data.upstream = row.upstream
      this.form_data.upstream_port = row.upstream_port
      this.form_data.failover_upstream = row.failover_upstream
      this.form_data.failover_upstream_port = row.failover_upstream_port
      this.form_data.domain_name = row.domain_name
      this.form_data.ip_address = row.ip_address
      this.form_data.config_type = row.config_type
      this.form_data.is_ssl = row.is_ssl == 1
      this.form_data.is_upstream_ssl = row.is_upstream_ssl == 1
      this.form_data.config_id = row.config_id
      this.form_data.reference_name = row.reference_name
      this.form_data.context = play_json
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
    add () {
      this.reset()
      this.getAllContext()
      this.form_data.upstream = ''
      this.form_data.upstream_port = ''
      this.form_data.failover_upstream = ''
      this.form_data.failover_upstream_port = ''
      this.form_data.domain_name = ''
      this.form_data.ip_address = ''
      this.form_data.config_type = ''
      this.form_data.reference_name = ''
      this.form_data.context = []
      this.form_data.is_ssl = true
      this.form_data.is_upstream_ssl = true
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
    },
    reset () {
      this.error_msg.upstreamErrors = []
      this.error_msg.failoverupstreamErrors = []
      this.error_msg.failoverportErrors = []
      this.error_msg.radioErrors = []
      this.error_msg.portErrors = []
      this.error_msg.domain_name = []
      this.error_msg.unicastIPErrors = []
      this.error_msg.ReferenceErrors = []
      this.error_msg.PlayErrors = []
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllConfigs()
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
<style lang="scss">
.radio_options {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
</style>
